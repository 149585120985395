import { NotFoundError } from '@newfront-insurance/data-layer-client';
import { useQuery } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import type { ReactNode } from 'react';

import { ClientPageLayout } from '@/client/components/client-dash-page-layout';
import { useNavigationLinks } from '@/client/components/client-dash-page-layout/lib/use-navigation-links';
import { AccountLayout } from '@/client/components/layouts/account-layout';
import { AccountDataProvider } from '@/client/providers/account-data';
import { ServicingType } from '@/client/providers/recent-service-type';
import { isPnCAccount } from '@/lib/guards';

interface Props {
  children: ReactNode;
}

export function PropertyAndCasualtyLayout({ children }: Props): JSX.Element {
  return (
    <AccountLayout>
      <PropertyAndCasualtyLayouInner>{children}</PropertyAndCasualtyLayouInner>
    </AccountLayout>
  );
}

function PropertyAndCasualtyLayouInner({ children }: Props): JSX.Element {
  const { serviceTypes } = useProvider(AccountDataProvider);
  const accountUuid = useQuery<string>('accountUuid');
  const { clientAccessFeatures } = useProvider(AccountDataProvider);
  if (!isPnCAccount(serviceTypes)) {
    throw new NotFoundError('The page you are looking for was not found');
  }
  const navigationLinks = useNavigationLinks(accountUuid, clientAccessFeatures);

  return (
    <ClientPageLayout navigationLinks={navigationLinks} serviceType={ServicingType.PROPERTY_AND_CASUALTY}>
      {children}
    </ClientPageLayout>
  );
}
