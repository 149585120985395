import { CircleSpinner, CircleSpinnerInline, Flexbox } from '@newfront-insurance/core-ui';

import { useFeatureFlag, FeatureFlags } from '@/client/providers/feature-flag';

interface LoadingSpinnerProps {
  loadingText: string;
  inline?: boolean;
}

export function LoadingSpinner({ loadingText, inline = false }: LoadingSpinnerProps): JSX.Element {
  const isSideBarLayoutEnabled = useFeatureFlag(FeatureFlags.SIDE_BAR_LAYOUT, false);

  if (isSideBarLayoutEnabled) {
    return (
      <Flexbox height="100%" width="100%" alignItems="center" justifyContent="center">
        <CircleSpinnerInline label={loadingText} />
      </Flexbox>
    );
  }

  return inline ? <CircleSpinnerInline label={loadingText} /> : <CircleSpinner label={loadingText} />;
}
