import { NotFoundError } from '@newfront-insurance/data-layer-client';
import type { Route } from '@newfront-insurance/next-router-provider';
import { useRouter } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';

import { PropertyAndCasualtyLayout } from '@/client/components/layouts/insurance-layout';
import { LoadingSpinner } from '@/client/components/query-loader/loading-spinner';
import { AccountDataProvider } from '@/client/providers/account-data';
import { RecentlyViewedServiceTypeProvider, ServicingType } from '@/client/providers/recent-service-type';
import { pageRoutes } from '@/client/routes';

export default function LandingPage(): JSX.Element {
  return (
    <PropertyAndCasualtyLayout>
      <Redirect>
        <LoadingSpinner loadingText="Loading..." />
      </Redirect>
    </PropertyAndCasualtyLayout>
  );
}

function Redirect(props: PropsWithChildren<unknown>): JSX.Element {
  const { children } = props;
  const { serviceTypes, accountUuid, defaultServiceType } = useProvider(AccountDataProvider);
  const { recentlyViewedServiceType } = useProvider(RecentlyViewedServiceTypeProvider);
  const { pushRoute } = useRouter();

  useEffect(() => {
    const route = getServicingTypeRoute(accountUuid, recentlyViewedServiceType || defaultServiceType);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    pushRoute(route);
  }, [accountUuid, defaultServiceType, pushRoute, recentlyViewedServiceType, serviceTypes]);

  return <>{children}</>;
}

/**
 * Get the root route for a servicing type.
 */
function getServicingTypeRoute(accountUuid: string, serviceType: ServicingType): Route {
  if (serviceType === ServicingType.PROPERTY_AND_CASUALTY) {
    return pageRoutes.clientHome({ accountUuid });
  }
  if (serviceType === ServicingType.EMPLOYEE_BENEFITS) {
    return pageRoutes.clientHome({ accountUuid });
  }
  throw new NotFoundError('Service type not found.');
}
